import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const utmParameters = `?utm_source=starter&utm_medium=start-page&utm_campaign=default-starter`

const IndexPage = () => (
    <Layout>
        {/* 00B0FF */}
        <section class="text-gray-600 bg-gradient-to-r from-cyan-500 to-blue-300 body-font">
            <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
                <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
                    <StaticImage loading="eager" placeholder="tracedSVG" class="object-cover object-center rounded" alt="hero" src="../images/undraw_monitor_iqpq.svg" />
                </div>
                <div class="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
                    <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">Stop working for your computer.<br />Let your computer work for you.</h1>
                    <p class="mb-8 leading-relaxed">
                        Smartbuddy's mission: to make personal computing easy, engaging, rewarding, and fun.
                        We understand that confusing errors, slow applications, and obnoxious pop-ups are not enjoyable and highly disruptive.
                        To see what solutions we have come up with for these issues, take a look around!
                    </p>
                    <div class="flex w-full md:justify-start justify-center items-end">
                        <div class="relative mr-4 lg:w-full xl:w-1/2 w-2/4">
                            <label for="hero-field" class="leading-7 text-sm text-gray-600">Stay up to date on our development:</label>
                            <input type="text" id="hero-field" placeholder="you@your-email.com" name="hero-field" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:ring-2 focus:ring-blue-200 focus:bg-gray-200 focus:border-blue-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"></input>
                        </div>
                        <button class="inline-flex text-gray-900 dark:text-white bg-blue-500 border-0 py-2 px-6 focus:outline-none hover:bg-blue-600 rounded text-lg">Notify me!</button>
                    </div>
                    <p class="text-sm mt-2 text-gray-500 mb-8 w-full">
                        By entering your email, you will receive newsletters related to Smartbuddy. <br />
                        Unsubscribe at any time.
                    </p>
                    <div class="flex lg:flex-row md:flex-col">
                        {/* <button class="bg-gray-100 inline-flex py-3 px-5 rounded-lg items-center hover:bg-gray-200 focus:outline-none">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="w-6 h-6" viewBox="0 0 512 512">
                                <path d="M99.617 8.057a50.191 50.191 0 00-38.815-6.713l230.932 230.933 74.846-74.846L99.617 8.057zM32.139 20.116c-6.441 8.563-10.148 19.077-10.148 30.199v411.358c0 11.123 3.708 21.636 10.148 30.199l235.877-235.877L32.139 20.116zM464.261 212.087l-67.266-37.637-81.544 81.544 81.548 81.548 67.273-37.64c16.117-9.03 25.738-25.442 25.738-43.908s-9.621-34.877-25.749-43.907zM291.733 279.711L60.815 510.629c3.786.891 7.639 1.371 11.492 1.371a50.275 50.275 0 0027.31-8.07l266.965-149.372-74.849-74.847z"></path>
                            </svg>
                            <span class="ml-4 flex items-start flex-col leading-none">
                                <span class="text-xs text-gray-600 mb-1">GET IT ON</span>
                                <span class="title-font font-medium">Google Play</span>
                            </span>
                        </button>
                        <button class="bg-gray-100 inline-flex py-3 px-5 rounded-lg items-center lg:ml-4 md:ml-0 ml-4 md:mt-4 mt-0 lg:mt-0 hover:bg-gray-200 focus:outline-none">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="w-6 h-6" viewBox="0 0 305 305">
                                <path d="M40.74 112.12c-25.79 44.74-9.4 112.65 19.12 153.82C74.09 286.52 88.5 305 108.24 305c.37 0 .74 0 1.13-.02 9.27-.37 15.97-3.23 22.45-5.99 7.27-3.1 14.8-6.3 26.6-6.3 11.22 0 18.39 3.1 25.31 6.1 6.83 2.95 13.87 6 24.26 5.81 22.23-.41 35.88-20.35 47.92-37.94a168.18 168.18 0 0021-43l.09-.28a2.5 2.5 0 00-1.33-3.06l-.18-.08c-3.92-1.6-38.26-16.84-38.62-58.36-.34-33.74 25.76-51.6 31-54.84l.24-.15a2.5 2.5 0 00.7-3.51c-18-26.37-45.62-30.34-56.73-30.82a50.04 50.04 0 00-4.95-.24c-13.06 0-25.56 4.93-35.61 8.9-6.94 2.73-12.93 5.09-17.06 5.09-4.64 0-10.67-2.4-17.65-5.16-9.33-3.7-19.9-7.9-31.1-7.9l-.79.01c-26.03.38-50.62 15.27-64.18 38.86z"></path>
                                <path d="M212.1 0c-15.76.64-34.67 10.35-45.97 23.58-9.6 11.13-19 29.68-16.52 48.38a2.5 2.5 0 002.29 2.17c1.06.08 2.15.12 3.23.12 15.41 0 32.04-8.52 43.4-22.25 11.94-14.5 17.99-33.1 16.16-49.77A2.52 2.52 0 00212.1 0z"></path>
                            </svg>
                            <span class="ml-4 flex items-start flex-col leading-none">
                                <span class="text-xs text-gray-600 mb-1">Download on the</span>
                                <span class="title-font font-medium">App Store</span>
                            </span>
                        </button> */}
                    </div>
                </div>
            </div>
        </section>
        {/* Smartbuddy diagnoses, repairs, and prevents issues with your computer completely in the background, and will notify you if any your interaction is required.  */}
        <section class="text-gray-400 body-font bg-gray-900">
            <div class="container flex flex-wrap px-5 py-12 mx-auto items-center">
                <div class="md:w-1/2 md:pr-12 md:py-8 md:border-r md:border-b-0 md:mb-0 mb-10 pb-10 border-b border-gray-800">
                    <div class="flex items-center">
                        <h1 class="sm:text-3xl text-2xl font-medium title-font mb-2 text-white w-max">Skip the journey</h1>
                        {/* <span class="bg-blue-500 text-gray-900 dark:text-white px-3 py-1 text-xs ml-4 rounded">FREE!</span> */}
                    </div>
                    <p class="leading-relaxed text-base">
                        The SmartPage greets you when you turn your computer on, providing quick access to the apps and services you use most.
                        SmartPage can also display the weather near you, and optionally displays news of interest about your computer and your security.
                    </p>
                    <a class="text-blue-400 inline-flex items-center mt-4">Create your own SmartPage for FREE
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                            <path d="M5 12h14M12 5l7 7-7 7"></path>
                        </svg>
                    </a>
                </div>
                <div class="lg:w-1/2 p-8 w-full mb-10 lg:mb-0 rounded-lg overflow-hidden">
                    <StaticImage loading="lazy" placeholder="blurred" class="object-cover object-center rounded h-full w-full" alt="feature" src="../images/smartpage-mockup-combined-right.png" />
                </div>
            </div>
        </section>

        <section class="text-gray-400 body-font bg-cyan-900">
            <div class="container flex flex-wrap px-5 py-12 mx-auto items-center">
                <div class="lg:w-1/2 p-8 w-full mb-10 lg:mb-0 rounded-lg overflow-hidden">
                    <StaticImage loading="lazy" placeholder="tracedSVG" class="object-cover object-center rounded h-full w-full" alt="feature" src="../images/undraw_data_trends_re_2cdy.svg" />
                </div>
                <div class="md:w-1/2 md:pl-12 md:py-8 md:border-l md:border-b-0 md:mb-0 mb-10 pb-10 border-b border-gray-800">
                    <div class="flex items-center">
                        <h1 class="sm:text-3xl text-2xl font-medium title-font mb-2 text-white w-max">Maintenance is easy</h1>
                    </div>
                    <p class="leading-relaxed text-base">
                        Most computer exploits and failures stem from lack of maintenance and monitoring. Smartbuddy PowerPack watches your computer for problems and fixes them when issues arise.
                    </p>
                    <a class="text-blue-400 inline-flex items-center mt-4">Coming soon!
                        {/* <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                            <path d="M5 12h14M12 5l7 7-7 7"></path>
                        </svg> */}
                    </a>
                </div>
            </div>
        </section>

        <section class="text-gray-400 bg-gray-900 body-font">
            <div class="container px-5 py-24 mx-auto">
                <div class="flex flex-col text-center w-full mb-20">
                    <h1 class="text-2xl font-medium title-font mb-4 text-white tracking-widest">OUR TEAM</h1>
                    <p class="lg:w-2/3 mx-auto leading-relaxed text-base">Meet the team that wants to make your personal computer an ally, rather than an adversary.</p>
                </div>
                <div class="flex flex-wrap -m-4">
                    <div class="p-4 lg:w-1/2">
                        <div class="h-full flex sm:flex-row flex-col items-center sm:justify-start justify-center text-center sm:text-left">
                            <StaticImage loading="lazy" placeholder="blurred" class="flex-shrink-0 rounded-lg w-48 h-48 object-cover object-center sm:mb-0 mb-4" alt="picture of Bud Stolker" src="../images/bud-photo.webp" />
                            <div class="flex-grow sm:pl-8">
                                <h2 class="title-font font-medium text-lg text-white">Bud Stolker</h2>
                                <h3 class="text-gray-500 mb-3">Co-Founder</h3>
                                <p class="mb-4">
                                    In the early 1980s, Bud developed an intuitive user interface for a national publisher's proposed operating system.
                                    He has toured nationally, advising educators on changing trends in the PC industry.
                                    His interest in the way we work with computers has culminated in Smartbuddy, designed to make technology easier for everyone.
                                </p>
                                <span class="inline-flex">
                                    <a class="text-gray-500">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                                            <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                                        </svg>
                                    </a>
                                    <a class="ml-2 text-gray-500">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                                            <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
                                        </svg>
                                    </a>
                                    <a class="ml-2 text-gray-500">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                                            <path d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z"></path>
                                        </svg>
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="p-4 lg:w-1/2">
                        <div class="h-full flex sm:flex-row flex-col items-center sm:justify-start justify-center text-center sm:text-left">
                            <StaticImage loading="lazy" placeholder="blurred" class="flex-shrink-0 rounded-lg w-48 h-48 object-cover object-center sm:mb-0 mb-4" alt="picture of Alex Sizov" src="../images/sizov-photo.webp" />
                            <div class="flex-grow sm:pl-8">
                                <h2 class="title-font font-medium text-lg text-white">Alex Sizov</h2>
                                <h3 class="text-gray-500 mb-3">Co-Founder</h3>
                                <p class="mb-4">
                                    Alex Sizov is a full stack developer who enjoys exploring new technologies and applying them to the modern world.<br />
                                    Alex has also developed software for the EzLung, a fast-to-market medical ventilator designed to aid patients with COVID-19 in hospitals.<br />
                                    Feel free to reach out!
                                </p>
                                <span class="inline-flex">
                                    <a class="text-gray-500">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                                            <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                                        </svg>
                                    </a>
                                    <a class="ml-2 text-gray-500">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                                            <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
                                        </svg>
                                    </a>
                                    <a class="ml-2 text-gray-500">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                                            <path d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z"></path>
                                        </svg>
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
    </Layout>
)

export const Head = () => <Seo title="Home" />

export default IndexPage
